.pesquisa {
    position: relative;
}
.lista-vendas-site h1 {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    margin-right: 510px;
    padding: 5px;
    color: #374ddc;
    padding: 10px 0 15px 0;
    font-size: x-large;
    text-shadow:  -5px 5px 15px rgba(0, 0, 0, 0.4);
}
.btn-cliA {
    margin-right: 5px;
    padding: 5px;
    height: 35px;
    width: 140px;
    align-items: center;
    text-align: center;
    color: #ffff;
    font-size: small;
}
.btn-cli2B {
    margin-right: 5px;
    padding: 5px;
    height: 35px;
    width: 140px;
    align-items: center;
    text-align: center;
    color: #ffff;
    font-size: small;
}
.btn-cli2A {
    margin-right: 5px;
    padding: 5px;
    height: 35px;
    width: 80px;
    align-items: center;
    text-align: center;
    color: black;
    font-size: small;
}
.nota {
    margin-top: 10px;
}

.over {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.script-modal-content {
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    position: relative;
    width: 90%;
    max-width: 600px;
    animation: fadeInUp 0.5s ease;
}
.close {
    position: absolute;
    top: 30px;
    right: 10px;
    cursor: pointer;
    font-size: 30px;
    color: red;
}
.over.closed .script-modal-content {
    animation: fadeOutDown 0.5s ease;
}
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
@keyframes fadeOutDown {
    from {
        opacity: 1;
        transform: none;
    }
    to {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
}
.background7 {
    border-top: 2px solid #ffff;
    border-bottom: 2px solid rgb(165, 155, 155);
    background: linear-gradient(to right, #374ddc, #800020);
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
}
.background7::before {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: repeat;
    opacity: 0.1;
    z-index: -1;
}
.background01 {
    border-top: 2px solid #ffff;
    border-bottom: 2px solid rgb(165, 155, 155);
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
}
.background01::before {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: repeat;
    opacity: 0.1;
    z-index: -1;
}
.botao-pesquisa-container {
    overflow: hidden;
    border-top-right-radius: 70px;
    border-bottom-right-radius: 70px;
}
.btn-pesquisa {
    border-radius: 0!important;
    height: 50px;
    background-color: #374ddc;
}
.lista-vendas-site {
    width: 100%;
    background-color: #ffff;
    padding: 40px 40px 20px;
}
.barra {
    border-radius: 70px;
    height: 50px;
}
.btn-new2{
    color: #fff;
    height: 50px;
    width: max-content;
    margin-right: 15px;
    font-size: x-large;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);

}
.btn-new2:hover{
    color: #ffffff;
    height: 50px;
    width: max-content;
    margin-right: 15px;
    font-size: x-large;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
}

.bg{
    background-color: #374ddc;
}

.div-baixo{
    background-color: #fff;
}
.divAgenciados i{
    color: #000000;
}

.qtdClientesAss{
    margin-right: 50px;
}
