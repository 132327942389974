.background-video-dash {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -1; /* Ajuste conforme necessário */
    /* Remova temporariamente o filtro */
    /* filter: brightness(0.4); */
  }

  .bg-dash{
    background: linear-gradient(120deg, #7bb2cc 50%, #2c7597 50%);
  }
  
  
.search-result {
    display: flex;
    align-items: flex-start;
    border: 1px solid #ddd;
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: rgba(255,255,255,.9);
    height: auto;
    margin-block: 10px;
}

.search-result .text-content {
    flex: 1;
}

.search-result iframe {
    width: 250px;
    height: 220px;
    border: 1px solid #ddd;
    border-radius: 0 15px 15px 0;
    flex-shrink: 0;
}

.img-dash{
    width: 250px!important;
    height: 220px;
    object-fit: cover;
    border-radius: 15px 0 0 15px;
    
  }

.text-content h3{
    letter-spacing: 2px;
    margin-bottom: 22px ;
}

.text-content h4{
    letter-spacing: 1px;
    margin-bottom: 17px ;
}

.text-content p{
    text-align: justify;
    margin-block: 10px ;
}

.search {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-top: 100px;
}

.search input {
    margin-inline: 20px;
    width: 300px;
}

.search-result img {
    width: 32px;
    margin: 5px;
}
.map-container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: auto;
}

.map-container img, .map{
    padding: 0!important;
    margin-inline: 0!important;
}

.results-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.result-description {
    text-align: justify;
    margin-right: 25px !important;
}

.descricao{
  padding-right: 50px;
}

.icons {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    padding-top: 40px;
}

.icons img {
    height: 37px;
    width: 37px;
    object-fit: cover;
}

.icons .btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: 8px;
    height: 40px;
}

.icons a:hover {
    filter: brightness(70%);
    scale: 1.07;
    transform: translateY(-3px);
}

.pagination{
    display: flex;
    justify-content: center;
    margin-block: 10px;
    align-items: center;
}

.pagination button {
    padding: 5px 10px;
    margin-inline: 10px;
    border: none;
    border-radius: 5px;
    background-color: #464e80;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
    background-color: #464cbd;
}

.pagination span {
    align-self: center;
    font-size: 16px;
    color: #464e80;
}

.custom-select-container {
    position: relative;
    width: 100%;
}

.custom-select-display {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom-select-dropdown {
    position: absolute;
    top: 100%;
    left: 5%;
    right: 0;
    max-height: 150px;
    overflow-y: auto;
    border: 1px solid #ddd;
    background: #fff;
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 53.4%;
    border-radius: 0 0 8px 8px;
}

.custom-select-dropdown li {
    padding: 8px;
    cursor: pointer;
}


.custom-select-dropdown li:hover {
    background-color: #007bff;
    color: #fff;

}

.inputs-search{
    display: flex;
}

.icon_copy{
    margin-left: 10px;
    font-size: 18px;
    cursor: pointer;
}

.btn-clear {
    background-color: #e74c3c; 
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .btn-clear:hover {
    background-color: #c0392b; 
    transform: scale(1.05); 
  }
  
  .btn-clear:active {
    background-color: #a93226; 
    transform: scale(0.98); 
  }

  .icon_location,
  .icon_clock, 
  .icon_telefone{
    margin-right: 10px;
  }
/* .icons i {
    padding: 0!important;
} */
  .icons img{
    cursor: pointer;
  }

  .icon_telefone{
    rotate: 100deg;
  }
  .fontawesome_tel{
    rotate: 100deg;
  }



  .status-open {
    color: green;
    font-weight: bold;
  }
  
  .status-closed {
    color: red;
    font-weight: bold;
  }

  .hidden{
    overflow: none;
  }
  

  @media screen and (max-width: 992px) {

    .inputs-search{
        display: flex!important;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .inputs-search input{
        margin-block: 10px;
    }

    .inputs-search .btn-clear{
        margin: auto;
    }

    .search-result iframe, .map-container img {
        width: 150px!important;
        height: 150px!important;
        padding: 0;
    }

    .search-result {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: auto;
        text-align: center;
    }

    .search-result .icons{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .map-container {
        margin-top: 10px;
    }
}

@media screen and (max-width: 992px) {
  h3 {
    font-size: 3rem; /* Aumenta o tamanho em telas menores */
  }

  h4 {
    font-size: 2rem; /* Aumenta o tamanho em telas menores */
  }

  .result-description {
    font-size: 1.1rem; /* Aumenta o tamanho em telas menores */
  }
}

@media (max-width: 576px) {
  h3 {
    font-size: 2rem; /* Aumenta ainda mais em telas muito pequenas */
  }

  h4 {
    font-size: 1.75rem; /* Aumenta ainda mais em telas muito pequenas */
  }

  .result-description {
    font-size: 1.2rem; /* Aumenta ainda mais em telas muito pequenas */
  }
}