input {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

/* Estilo ao focar no input */
input:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

img{
  width: 200px;
  object-fit: cover;
  object-position: center;
  margin: auto;
}